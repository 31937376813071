import gql from 'graphql-tag';
import { useQuery } from '@apollo/client';
import {
  Container,
  Divider,
  FlexGrid,
  Header,
  Picture,
  Text,
  Visibility,
} from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import { Fragment, useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import ReactCriteo from '../../../lib/utils/criteo';
import { ANALYTICS_EVENTS, COUNTRY_CODES } from '../../constants';
import * as TESTIMONIALS from '../../constants/testimonials';
import AnimatedPhone from '../AnimatedPhone';
import AppPromo from '../AppPromo';
import HomePageGasBackBrands from '../FeaturedGasbackBrands/HomePageGasBackBrands';
import GetTheAppButton from '../GetTheAppButton';
import HomeScreenCardModal from '../HomeScreenCardModal';
import Hero from '../LandingPageTemplate1/Hero';
import MobileDownloadAd from '../MobileDownloadAd';
import Page from '../Page';
import ReachMetrics from '../ReachMetrics';
import SavingsTools from '../SavingsTools';
import SearchStateCloud from '../SearchStateCloud';
import Testimonials from '../Testimonials';
import TestimonialCard from '../Testimonials/TestimonialCard';
import styles from './HomePage.module.css';
import useGeoIpInfo from '../../../lib/hooks/useGeoIpInfo';

const cx = classnames.bind(styles);

function HeroPhoneImage({
  ...rest
}) {
  const imagePath = 'https://static.gasbuddy.com/web/consumer/phones/iphone_X_list_CA_020520';

  return (
    <Picture
      alt="iPhone X With GasBuddy App"
      srcSet={`
        ${imagePath}@2x.png 2x,
        ${imagePath}@3x.png 3x
      `}
      src={`${imagePath}.png`}
      {...rest}
    >
      <Picture.Source
        srcSet={`
          ${imagePath}.webp,
          ${imagePath}@2x.webp 2x,
          ${imagePath}@3x.webp 3x
        `}
        type="image/webp"
      />
    </Picture>
  );
}

const GET_PAY_PAGE = gql`
  query PayPage {
    offers {
      partner {
        imageUrl
        name
      }
      tags
    }
    marketingMetrics {
      savedByUser
      platformTotals {
        key
        value
      }
    }
    page(url: "pay") {
      ... on CMSLandingPage {
        product {
          offers {
            price {
              amount
            }
          }
        }
        sections {
          ctaLabel
          ctaUrl
          description
          image {
            url
          }
          imageAlt
          mobileImage {
            url
          }
          name
          title
        }
      }
    }
  }
`;

export default function Homepage() {
  const { country } = useGeoIpInfo();
  const testimonials = TESTIMONIALS[country] || TESTIMONIALS.CA;
  const isUSUser = country === COUNTRY_CODES.USA;
  const { data } = useQuery(GET_PAY_PAGE, {
    skip: !isUSUser,
  });
  const offers = data?.offers.map(offer => ({
    title: offer.partner?.name,
    logo: offer.partner?.imageUrl ? `//images.gasbuddy.io/autox100/${offer.partner.imageUrl}` : undefined,
    tags: offer.tags,
  })) || [];
  const { platformTotals, savedByUser } = data?.marketingMetrics || {};
  // filter out offers that don't have a logo so that we don't just show an empty bubble
  const featuredOffers = offers?.filter(({ logo, tags }) => tags && tags.includes('featured_web') && !!logo);

  const heroCMSSection = data?.page?.sections?.find(section => section.name === 'hero');
  const offerPrice = data?.page?.product?.offers?.[0]?.price?.amount;
  const location = useLocation();
  const analytics = useAnalytics();

  testimonials.sort((a, b) => b.id - a.id);

  useEffect(() => {
    ReactCriteo.event({
      event: 'viewHome',
    });
  }, []);

  const handleEnrollClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.PAY_CTA_CLICKED,
      attributes: {
        'Screen Name': 'Home',
        Section: 'PayPromo',
      },
    });
  }, [analytics]);

  let helmetTitle = 'GasBuddy - Cheapest Gas Station Finder App with Money Saving Benefits';
  let helmetDescription = 'Download the free GasBuddy app to find the cheapest gas stations near you, and save up to 40¢/gal by upgrading to a Pay with GasBuddy fuel rewards program.';

  if (country === COUNTRY_CODES.CANADA) {
    helmetTitle = 'GasBuddy - Cheapest Gas and Gas Station Locator in Canada';
    helmetDescription = 'GasBuddy is a community of drivers sharing real-time gas prices through our website and mobile app to help each other find the cheapest nearby gas stations.';
  }

  return (
    <div className={cx('container')}>
      <Page name="Home" compact>
        <Helmet>
          <title>{helmetTitle}</title>
          <meta property="og:title" content={helmetTitle} />
          <meta name="twitter:title" content={helmetTitle} />
          <meta name="description" content={helmetDescription} />
          <meta property="og:description" content={helmetDescription} />
          <meta name="twitter:description" content={helmetDescription} />
          <link rel="alternate" href="https://www.gasbuddy.com" hrefLang={`en-${country.toLowerCase()}`} />
        </Helmet>
        <HomeScreenCardModal />
        <div className={cx('content')}>
          <Visibility desktop={false} tablet={false}>
            <Container>
              <MobileDownloadAd analyticsScreenName="Home_Page" />
              <br />
              <GetTheAppButton cta={false} screen="Home" section="Mobile Top App Promo" />
              <AnimatedPhone country={country} />
            </Container>
          </Visibility>
          {heroCMSSection ? (
            <Hero
              buttonHref={`${heroCMSSection.ctaUrl}${location.search}`}
              heroData={heroCMSSection}
              offerPrice={offerPrice}
              onButtonClick={handleEnrollClick}
            />
          ) : (
            <div className={cx('hero')}>
              <div className={cx('header')}>
                <Container>
                  <Header className={cx('restrict', 'mainHeader')} color="white" elongated uppercase>
                    Never Pay Full Price For Gas Again.
                  </Header>
                </Container>
              </div>
              <Container className={cx('imageContainer')}>
                <HeroPhoneImage
                  className={cx('phone')}
                />
              </Container>
              <Container>
                <FlexGrid>
                  <FlexGrid.Column desktop={10}>
                    <Header as="h2" className={cx('lead')}>
                      Find the best gas price, anywhere.
                    </Header>
                    <Text as="p" className={cx('sublead')}>
                      Fuel better by always knowing what the cheapest price is and where the best stations are.
                    </Text>
                    <br />
                    <br />
                    <GetTheAppButton className={cx('mobileFluid')} screen="Home" section="Hero Promo" />
                  </FlexGrid.Column>
                </FlexGrid>
              </Container>
              <Visibility mobile tablet={false} desktop={false}>
                <HeroPhoneImage />
              </Visibility>
            </div>
          )}
          {isUSUser && (
            <div className={cx('pwgbContainerWrapper')}>
              <HomePageGasBackBrands
                brands={featuredOffers}
                className={cx('gasBackOffers')}
              />
            </div>
          )}
          {!!(savedByUser && platformTotals) && (
            <Fragment>
              <Container className={cx('metricsContainer')}>
                <div className={cx('metricsOffset')}>
                  <ReachMetrics platformTotals={platformTotals} country={country} />
                </div>
              </Container>
              <Divider className={cx('homeDivider')} />
              <Container>
                <Testimonials className={cx('testimonials')}>
                  {testimonials.map(({ username, text, name, stars = 0 }) => (
                    <TestimonialCard
                      key={name}
                      text={text}
                      from={name}
                      stars={stars}
                      saved={savedByUser[username]}
                    />
                  ))}
                </Testimonials>
              </Container>
            </Fragment>
          )}
          <AppPromo className={cx('homeAppPromo')} country={country} screenName="Home" />
          <SavingsTools className={cx('savings')} />
          <Container className={cx('regionsContainer')} fluid>
            <SearchStateCloud country={country} />
          </Container>
        </div>
      </Page>
    </div>
  );
}
